const products = [
  {
    id: 1,
    name: "Kannadiga Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431703/PLBKP001D001_zodz4m.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 2,
    name: "Kannadiga Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431939/PLBKP002D002_oj1rfh.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.2,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 3,
    name: "Kannadiga Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431941/PLBKP003D003_kgn4li.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 4,
    name: "Kannadathi Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431945/PLBKP004D004_b4dbet.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 5,
    name: "Kannadathi Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431945/PLBKP005D005_ttgxx2.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.1,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 6,
    name: "Kannadathi Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431950/PLBKP006D006_jaljmn.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 7,
    name: "Kannadave sathya kannadave nithya, Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431817/PLBKP007D007_gwbafu.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 9,
    name: "Karnataka Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431710/PLBKP009D009_htahpz.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 10,
    name: "Karnataka Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431793/PLBKP010D010_fcmthc.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.1,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 11,
    name: "Karnataka Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431810/PLBKP011D012_g0uldz.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.2,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 12,
    name: "Karnataka Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729432081/PLBKP012D013_ygmzd1.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 13,
    name: "Karunadu Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431769/PLBKP013D014_iwcyfd.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 14,
    name: "Karnataka flag Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431946/PLBKP014D015_st5xbn.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 15,
    name: "Karunadu Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431812/PLBKP015D017_s8ugir.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 16,
    name: "Karunadu Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431815/PLBKP016D018_au9t8q.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 17,
    name: "Bengaluru Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431809/PLBKP017D020_jxkwsw.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.2,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 18,
    name: "Bengaluru Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431815/PLBKP018D021_iaiurv.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 19,
    name: "Auto riksha Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431817/PLBKP019D022_pigqoh.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.1,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 20,
    name: "Gandaberunda Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431820/PLBKP020D023_w24npj.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.1,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 21,
    name: "Immadi Pulikeshi Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431826/PLBKP021D027_n9tnqn.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.5,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 22,
    name: "Sangolli Rayanna Black Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431825/PLBKP022D029_cx5ky7.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "Black",
    gsm: 240,
    rating: 4.5,
    seoDescription: "Kannadiga Black Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-black-polo-tshirt",
    alt: "Kannadiga Black Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 101,
    name: "Kannadiga white Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431857/PLWTP001D001_nj0jw2.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 102,
    name: "Kannadiga White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431909/PLWTP002D002_kkrquu.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.2,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 103,
    name: "Kannadiga White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431842/PLWTP003D003_zto1mb.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 104,
    name: "Kannadathi White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431877/PLWTP004D004_kv92fu.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 105,
    name: "Kannadathi White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431886/PLWTP005D005_lrbx5g.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.1,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 106,
    name: "Kannadathi White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431884/PLWTP006D006_omkzso.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 107,
    name: "Kannadave sathya kannadave nithya, white Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431887/PLWTP007D007_ffuexa.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 108,
    name: "Karnataka White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431919/PLWTP008D008_ah1iir.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 109,
    name: "Karnataka White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431912/PLWTP009D011_u47ida.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.1,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 110,
    name: "Karnataka  Flag White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431946/PLWTP010D015_csqm99.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.2,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 111,
    name: "Karunadu White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431939/PLWTP011D016_fdktcc.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 112,
    name: "Bengaluru White  Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431950/PLWTP012D019_gu0eca.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 113,
    name: "Auto riksha white Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431947/PLWTP013D022_ls1mwl.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.3,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 114,
    name: "Gandaberunda White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431703/PLWTP014D023_m2hguk.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 115,
    name: "Yakshagana White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431781/PLWTP015D024_wa3u2i.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.2,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 116,
    name: "Kannada poet white Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431775/PLWTP016D025_yapw3j.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.4,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 117,
    name: "Immadi Pulikeshi White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431778/PLWTP017D027_rgzhws.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.1,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 118,
    name: "Sangolli Rayanna White Polo T-shirt",
    price: 579,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729431779/PLWTP018D029_owkhf5.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Ribbed collar and cuff.",
    category: "Polo",
    color: "White",
    gsm: 240,
    rating: 4.1,
    seoDescription: "Kannadiga White Polo T-shirt with ribbed collar, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-polo-tshirt",
    alt: "Kannadiga White Polo T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 201,
    name: "Kannadiga Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446235/RNBKP001D001_bdyfzn.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 202,
    name: "Kannadiga Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446145/RNBKP002D002_acc9wv.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 203,
    name: "Kannadiga Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446238/RNBKP003D003_ysoehl.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 204,
    name: "Kannadathi Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446245/RNBKP004D004_bbkurf.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.4,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 205,
    name: "Kannadathi Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446241/RNBKP005D005_fdto89.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.1,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 206,
    name: "Kannadathi Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446238/RNBKP006D006_wjgdql.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 207,
    name: "Kannadave sathya Kannadave nithya Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446243/RNBKP007D007_rxdfk1.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 208,
    name: "Karnataka Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446243/RNBKP008D009_vajmfz.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 209,
    name: "Karnataka Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446248/RNBKP009D010_gv4u2c.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.1,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 210,
    name: "Karnataka Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446252/RNBKP010D012_uw347n.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 211,
    name: "Karnataka Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446248/RNBKP011D013_ygpcym.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.1,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 212,
    name: "C/O Karunadu Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446255/RNBKP012D014_qfp78v.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 213,
    name: "Namma Karunadu Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446248/RNBKP013D017_qhstib.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.1,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 214,
    name: "Namma Karunadu Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446252/RNBKP014D018_efawq3.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.1,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 215,
    name: "Bengaluru Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446255/RNBKP015D020_gtoezo.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.4,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 216,
    name: "Bengaluru Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446162/RNBKP016D021_mennhz.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 217,
    name: "Auto Riksha Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446261/RNBKP017D022_dpyonk.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 218,
    name: "Gandaberunda Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446145/RNBKP018D023_ng80dg.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 219,
    name: "Immadi pulikeshi Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446242/RNBKP019D027_zhoxaw.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.5,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 220,
    name: "Sangolli Rayanna Black Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446153/RNBKP020D029_bnckan.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "Black",
    gsm: 180,
    rating: 4.5,
    seoDescription: "Kannadiga Black Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Round-Neck-T-shirt",
    alt: "Kannadiga Black Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 301,
    name: "Kannadiga White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446148/RNWTP001D001_sn5w59.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 302,
    name: "Kannadiga White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446152/RNWTP002D002_zfb1i6.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.4,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 303,
    name: "Kannadiga White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446165/RNWTP003D003_u1u5te.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 304,
    name: "Kannadathi White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446165/RNWTP004D004_dzw0yh.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 305,
    name: "Kannadathi White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446165/RNWTP005D005_ehn0oj.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.1,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 306,
    name: "Kannadathi White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446199/RNWTP006D006_gljkzv.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.1,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 307,
    name: "Kannadave sathya Kannadave Nithya White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446241/RNWTP007D007_simzt8.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 308,
    name: "Karnataka White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446208/RNWTP008D008_oqbqji.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 309,
    name: "Karnataka White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446173/RNWTP009D011_pqovtu.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 310,
    name: "C/O Karunadu White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446204/RNWTP010D014_t2qfix.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.4,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 311,
    name: "Namma Karunadu White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446252/RNWTP011D016_gmwfpn.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 312,
    name: "BengaluruWhite Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446262/RNWTP012D019_pzq9cv.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 313,
    name: "Auto Riksha White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446254/RNWTP013D022_cptat0.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.1,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 314,
    name: "Gandaberunda White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446258/RNWTP014D023_lwecwl.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 315,
    name: "Yakshagana White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446260/RNWTP015D024_ycrs1u.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.3,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 316,
    name: "Kannada poet White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446262/RNWTP016D025_sofs2v.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.4,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 317,
    name: "Immadi pulikeshi White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446259/RNWTP017D027_x0rspl.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.2,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 318,
    name: "Sangolli Rayanna White Round Neck T-shirt",
    price: 479,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729446261/RNWTP018D029_prbhfg.jpg",
    features:
      "100% Ring spun supper combed cotton,Bio washed,Color fastness guranteed",
    category: "Round Neck",
    color: "White",
    gsm: 180,
    rating: 4.5,
    seoDescription: "Kannadiga White Round Neck T-shirt, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Round-Neck-T-shirt",
    alt: "Kannadiga White Round Neck T-shirt, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 401,
    name: " Kannadiga Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455036/HDBKP001D001_nxj9nb.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 402,
    name: " Kannadiga Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455038/HDBKP002D002_tlkqjg.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.2,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 403,
    name: "Kannadiga Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455037/HDBKP003D003_nrmwtn.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 404,
    name: "Kannadathi Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455039/HDBKP004D004_stname.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 405,
    name: "Kannadathi Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455039/HDBKP005D005_pfcu64.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 406,
    name: "Kannadathi Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455038/HDBKP006D006_gkdcyg.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.1,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 407,
    name: "Kannadave sathya Kannadave nithya Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455038/HDBKP007D007_xzdm3v.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.4,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 408,
    name: "Karnataka Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455039/HDBKP008D009_gmlcnr.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.6,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 409,
    name: "Karnataka Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455040/HDBKP009D010_y9mqri.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.4,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 410,
    name: " Karnataka Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455041/HDBKP010D012_lwg1yo.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 411,
    name: "Karnataka Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455040/HDBKP011D013_jq7xjt.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.2,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 412,
    name: "C/O Karunadu Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455041/HDBKP012D014_ful3su.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.6,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 413,
    name: "Namma Karunadu Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455041/HDBKP013D017_lphdrg.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 414,
    name: "Namma Karunadu Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455042/HDBKP014D018_sdgywo.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.2,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 415,
    name: "Bengaluru Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455042/HDBKP015D020_slugbk.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 416,
    name: "Bengaluru Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455043/HDBKP016D021_jqfu1y.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.1,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 417,
    name: "Auto riksha Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455042/HDBKP017D022_i2mmqi.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.5,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 418,
    name: "Gandaberunda Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455043/HDBKP018D023_bi3oq3.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.6,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 419,
    name: "Immadi Pulikeshi Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455050/HDBKP019D027_d44n3a.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.4,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 420,
    name: "Sangolli Rayanna Black Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455035/HDBKP020D029_f5ul7z.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "Black",
    gsm: 300,
    rating: 4.6,
    seoDescription: "Kannadiga Black Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-Black-Pullover-Hoodies",
    alt: "Kannadiga Black Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 501,
    name: " Kannadiga White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455036/HDWTP001D001_z8fsxz.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 502,
    name: " Kannadiga White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455035/HDWTP002D002_z0bulr.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 503,
    name: " Kannadiga White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455037/HDWTP003D003_mdggnh.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 504,
    name: " Kannadathi White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455035/HDWTP004D004_suhhb7.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.2,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 505,
    name: " Kannadathi White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455043/HDWTP005D005_pufo2i.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 506,
    name: " Kannadathi White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455046/HDWTP006D006_ifsyrc.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.2,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 507,
    name: " Kannadave SathyaKannadave Nithya White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455049/HDWTP007D007_gqj2o4.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.5,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 508,
    name: " Karnataka White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455044/HDWTP008D008_gcbvgs.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 509,
    name: "Karnataka White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455044/HDWTP009D011_h096dp.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 510,
    name: " C/O Karunadu White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455049/HDWTP010D014_pqn9eu.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.6,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 511,
    name: " KarunaduWhite Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455049/HDWTP011D016_lzyafl.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 512,
    name: "Bengaluru White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455049/HDWTP012D019_mxjcye.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
  {
    id: 513,
    name: " Auto rikshaWhite Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455050/HDWTP013D022_zgq80y.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.6,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 514,
    name: "Gandaberunda White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455049/HDWTP014D023_w8fbja.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.6,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 515,
    name: "Yakshagana White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455052/HDWTP015D024_gpidyr.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.5,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 516,
    name: " Kannada Poet White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455053/HDWTP016D025_crkrbr.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.3,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 517,
    name: "Immadi Pulikeshi White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455053/HDWTP017D027_fydkuz.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.4,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },

  {
    id: 518,
    name: "Sangolli Rayanna White Pullover Hoodie(Unisex)",
    price: 799,
    image:
      "https://res.cloudinary.com/dpb5xdzmm/image/upload/v1729455052/HDWTP018D029_h05ksg.jpg",
    features:
      "100% Ring spun super combed cotton,Bio washed,Color fastness guranteed,Brushed inside.",
    category: "Pullover Hoodies",
    color: "White",
    gsm: 300,
    rating: 4.6,
    seoDescription: "Kannadiga White Pullover Hoodies, made from 100% cotton. bio-washed , shirtlessclub , shirtless club for durability and Kannada pride.",
    slug: "kannadiga-White-Pullover-Hoodies",
    alt: "Kannadiga White Pullover Hoodies, 100% cotton, ribbed collar, bio-washed , shirtlessclub , shirtless club",
  },
];

export default products;

import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import "./ProductCard.css";

function ProductCard({ product }) {
  const [quantity, setQuantity] = useState(1);

  // Handle WhatsApp Buy Click
  const handleBuyClick = () => {
    const phoneNumber = "6363057117"; // Your WhatsApp number
    const message = `Hello! I'm interested in purchasing the product: **${product.name}**. I would like to order **${quantity}** unit(s). Could you please provide me with more details?\n
    Here is the product image: ${product.image}`;
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  // Update the quantity
  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  return (
    <Card
      className="product-card">
      <Card.Img
        variant="top"
        src={product.image}
        alt={product.name}
        className="product-image"
      />
      <Card.Body>
        <Card.Title className="product-title">{product.name}</Card.Title>

        {/* Color */}
        <Card.Text className="product-Color">
          <strong>Color: {product.color}</strong>{" "}
        </Card.Text>

        {/* Rating and Reviews */}
        <p className="product-rating">
          Rating: {product.rating} / 5 ({product.reviews} reviews)
        </p>

        <p className="product-features">Features: {product.features}</p>

        {/* Price */}
        <Card.Text className="product-price">
          <strong>Price: Rs: {product.price}</strong>{" "}
        </Card.Text>

        {/* Buy on WhatsApp Button and Quantity Controls */}
        <div className="d-flex align-items-center mb-3 justify-content-between">
          {/* Buy on WhatsApp Button */}
          <Button
            className="buy-button me-2" // Added margin-end for spacing
            disabled={product.stock === 0}
            onClick={handleBuyClick}
          >
            Buy on WhatsApp
          </Button>

          {/* Quantity Controls */}
          <div className="quantity-controls d-flex align-items-center">
            <Button
              variant="outline-secondary"
              onClick={decreaseQuantity}
              disabled={quantity === 1}
            >
              -
            </Button>
            <span className="mx-2 quantity">{quantity}</span>
            <Button variant="outline-secondary" onClick={increaseQuantity}>
              +
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProductCard;

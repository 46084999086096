// src/pages/Cart.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Cart.module.css';

const Cart = () => {
  // Set document title and meta description for SEO
  useEffect(() => {
    const setDocumentMeta = (title, description) => {
      document.title = title;
      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
    };

    setDocumentMeta(
      'Cart - Shirtless Club', // Title for the Cart page
      'Review your cart at Shirtless Club. Explore the latest fashion items added to your cart.' // Description for SEO
    );

    // Cleanup function to reset the title and description if needed
    return () => {
      document.title = 'Shirtless Club - Your Fashion Destination'; // Reset title when leaving the page
      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", 'Shop the latest trends in fashion with Shirtless Club. Find the perfect T-shirts, hoodies, and more to match your style.');
      }
    };
  }, []);

  return (
    <div className={styles['cart-container']}>
      <h1 className={styles.errorCode}>Coming Soon!</h1>
      <h2 className={styles.errorMessage}>Oops! We are working on it.</h2>
      <p className={styles.errorDescription}>The cart feature is not available yet. Stay tuned!</p>
      <Link to="/" className={styles.homeButton}>Go to Home Page</Link>
    </div>
  );
};

export default Cart;

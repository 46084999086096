import React from 'react';
import styles from './Footer.module.css';

function Footer() {
  return (
    <footer className={styles.footer}>
      <p>© 2024 Shirtless Club. All rights reserved.</p>
      
      {/* Description for SEO */}
      <p className={styles.description}>Shirtless Club offers a range of Kannadiga-inspired T-shirts, hoodies, and polos with unique designs, perfect for expressing pride in style.</p>

      {/* Internal Links */}
      <nav>
        <ul>
          <li><a href="/shop" aria-label="Shop Kannadiga T-Shirts">Shop Kannadiga T-Shirts</a></li>
          <li><a href="/AboutUs" aria-label="About Shirtless Club">About Us</a></li>
        </ul>
      </nav>

      {/* Social Media Links */}
      <div className={styles.socialMedia}>
        <a href="https://www.instagram.com/shirtlessclub?igsh=cmZwN2UwbDRlMnp3" aria-label="Shirtless Club on Instagram">Instagram</a>
      </div>

      {/* Address */}
      <div className={styles.organizationInfo} itemScope itemType="http://schema.org/Organization">
        <p itemProp="name">Shirtless Club</p>
        <address itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          <span itemProp="streetAddress">123 Main St</span>, 
          <span itemProp="addressLocality">Bengaluru</span>, 
          <span itemProp="addressRegion">Karnataka</span>, 
          <span itemProp="postalCode">560001</span>
        </address>
        <p>Phone: <span itemProp="telephone">+91 63630571170</span></p>
      </div>
    </footer>
  );
}

export default Footer;

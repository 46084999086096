import React from 'react';
import './AboutUs.module.css';

const AboutUs = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1>Welcome to Shirtless Club!</h1>
        <p>
          At <strong>Shirtless Club</strong>, we believe in style, comfort, and
          quality. We are your go-to destination for premium clothing, including
          trendy T-shirts, hoodies, and round neck T-shirts. Our products are
          designed for those who appreciate modern fashion with an edge.
        </p>

        <p>
          We offer a curated selection of comfortable, high-quality apparel that
          you can book directly via WhatsApp. Whether you're looking for casual
          wear or something more unique, we've got you covered.
        </p>

        <h2>Our Products</h2>
        <ul>
          <li>Polo T-Shirts</li>
          <li>Round Neck T-Shirts</li>
          <li>Pullover Hoodies</li>
        </ul>

        <h2>How to Order</h2>
        <p>
          Placing an order is simple! Just reach out to us on WhatsApp at{' '}
          <a href="https://wa.me/916363057117" target="_blank" rel="noreferrer">
            +91 6363057117
          </a>{' '}
          to book your favorite apparel.
        </p>

        <p>
          Thank you for being a part of the <strong>Shirtless Club</strong>.
          We're excited to bring you the latest trends and help you express
          yourself with style.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;

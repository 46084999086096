import React, { useState } from 'react';
import productsData from '../data/products';
import ProductCard from '../components/ProductCard';
import { Container, Row, Col, Form } from 'react-bootstrap';
import './Shop.css'

function Shop() {

  const [category, setCategory] = useState('All');
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [color, setColour] = useState('All');
  const [minRating, setMinRating] = useState(0);
  const [sortOption, setSortOption] = useState('default'); // Sorting option state

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;

  
  const handleCategoryChange = (event) => setCategory(event.target.value);
  const handlePriceRangeChange = (event) => {
    const value = event.target.value.split(',').map(Number);
    setPriceRange(value);  // Ensure it's set as an array of numbers
  };
  const handleColourChange = (event) => setColour(event.target.value);
  const handleRatingChange = (event) => setMinRating(parseFloat(event.target.value));

  const handleSortChange = (event) => setSortOption(event.target.value); // Handle sorting option change

// Filter products
const filteredProducts = productsData.filter((product) => {
  if (category !== 'All' && product.category !== category) {
    return false;
  }
  // Ensure that product.price is between priceRange[0] and priceRange[1]
  if (product.price < priceRange[0] || product.price > priceRange[1]) {
    return false;
  }
  if (color !== 'All' && product.color !== color) {
    return false;
  }
  if (product.rating < minRating) {
    return false;
  }
  return true;
});

  // Sort products based on the selected sort option
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOption === 'low-to-high') {
      return a.price - b.price; // Sort by price ascending
    } else if (sortOption === 'high-to-low') {
      return b.price - a.price; // Sort by price descending
    } else {
      return 0; // Default sorting (no sorting)
    }
  });

  const totalPages = Math.ceil(sortedProducts.length / productsPerPage);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  return (
    <Container>
      <h1 className="my-4 text-center">Our Products</h1>

      {/* Filters and Sorting UI */}
      <Form className="mb-4">
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Select value={category} onChange={handleCategoryChange}>
                <option value="All">All</option>
                <option value="Polo">Polo T-Shirt</option>
                <option value="Round Neck">Round Neck T-Shirt</option>
                <option value="Pullover Hoodies">Pullover Hoodies</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Price Range</Form.Label>
              <Form.Select value={priceRange.join(',')} onChange={handlePriceRangeChange}>
                <option value="0,1000">All (up to 1000)</option>
                <option value="0,480">Up to 480</option>
                <option value="480,600">480 to 600</option>
                <option value="600,1000">600 to 1000</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Color</Form.Label>
              <Form.Select value={color} onChange={handleColourChange}>
                <option value="All">All</option>
                <option value="Black">Black</option>
                <option value="White">White</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Minimum Rating</Form.Label>
              <Form.Select value={minRating} onChange={handleRatingChange}>
                <option value="0">All Ratings</option>
                <option value="3">3 Stars & Up</option>
                <option value="4">4 Stars & Up</option>
                <option value="5">5 Stars Only</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        {/* Sorting UI */}
        <Row className="mt-3">
          <Col md={3}>
            <Form.Group>
              <Form.Label>Sort By</Form.Label>
              <Form.Select value={sortOption} onChange={handleSortChange}>
                <option value="default">Default</option>
                <option value="low-to-high">Price: Low to High</option>
                <option value="high-to-low">Price: High to Low</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      {/* Product Grid */}
      <Row>
        {currentProducts.map((product) => (
          <Col key={product.id} xs={12} sm={6} md={4} lg={3}>
            <ProductCard product={product} />
          </Col>
        ))}
      </Row>

      {/* No Products Message */}
      {currentProducts.length === 0 && (
        <p className="text-center">No products match your filter criteria.</p>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-center align-items-center my-4">
        <button 
          className="btn btn-light mx-2" 
          disabled={currentPage === 1} 
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          &lt; {/* Left arrow */}
        </button>

        <button className="btn btn-primary">
          Page {currentPage} of {totalPages}
        </button>

        <button 
          className="btn btn-light mx-2" 
          disabled={currentPage === totalPages} 
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          &gt; {/* Right arrow */}
        </button>
      </div>
    </Container>
  );
}

export default Shop;

import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Shop from './pages/Shop';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUs from './components/AboutUs';
import Cart from './pages/Cart';

// Lazy load the pages
const HomePage = lazy(() => import('./pages/Home'));
const NotFound = lazy(() => import('./pages/NotFound')); // Example for a 404 page

function App() {
  // Update the document title and meta tags based on the route
  useEffect(() => {
    const setDocumentMeta = (title, description) => {
      document.title = title;
      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
    };

    // Example route meta information
    const routesMeta = {
      '/': {
        title: 'Home - Shirtless Club',
        description: 'Welcome to Shirtless Club. Shop the latest trends in fashion, including stylish T-shirts and hoodies.'
      },
      '/shop': {
        title: 'Shop - Shirtless Club',
        description: 'Explore our shop for the latest fashion trends. Discover stylish T-shirts, hoodies, and accessories.'
      },
      '/AboutUs': {
        title: 'About Us - Shirtless Club',
        description: 'Learn more about Shirtless Club and our mission to provide the best fashion trends.'
      },
      '/Cart': {
        title: 'Cart - Shirtless Club',
        description: 'Review your cart and proceed to checkout at Shirtless Club.'
      },
      '*': {
        title: '404 Not Found - Shirtless Club',
        description: 'Oops! The page you are looking for does not exist.'
      }
    };

    const currentPath = window.location.pathname;
    const { title, description } = routesMeta[currentPath] || routesMeta['*'];
    setDocumentMeta(title, description);
  }, []);

  return (
    <Router>
      <div id="root"> {/* Main wrapper for the layout */}
        <Header />
        <main className="container">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/Cart" element={<Cart />} />
              <Route path="*" element={<NotFound />} /> {/* Handle unknown routes */}
            </Routes>
          </Suspense>
        </main>
        <Footer /> {/* Always at the bottom of the page */}
      </div>
    </Router>
  );
}

export default App;
